import { apiService } from "./api.service"
import { LOGGING_API_URL, APP_ENV } from "../constants/commonStrings.constant"

export class LogRequest {
    LogLevel: string = "Information";
    Message: string = "";
    Exception: string = "";
    CreatedAt: number = Math.floor(Date.now() / 1000);
    AppServiceName: string = "CollectWeb";
    Host: string = APP_ENV;
    CodeSource: string = "JavaScript / React";
    DatadogEnabled: boolean = true;
}

class LogService {
    async sendLog(payload: LogRequest): Promise<void> {
        const url = LOGGING_API_URL
        await apiService.post(
            url, 
            payload, 
            { withCredentials: false
                }
             )
      }
}

export const logService = new LogService();
