import type { OverridableStringUnion } from "@mui/types"
import type { BadgePropsColorOverrides } from "@mui/material"
import type { GeoJsonObject } from "geojson"
import { BASE_API_URL } from "../constants/commonStrings.constant"
import type { IFormAttachment, IFormTemplate } from "../interfaces/formResponse.interface"
import type { IFeatureFilterStatus, ISurveyFile } from "../interfaces/survey.interface"
import { apiService } from "./api.service"
import type { IFeature, IFeatureStatus, IFeatureStyled } from "../interfaces/feature.interface"
import { green, grey, red } from "@mui/material/colors"
import type { FeatureCollection } from "@turf/helpers"
import dayjs from "dayjs"

class FeatureService {
  async getFeatures(projectId: number, surveyId?: number): Promise<IFeature[]> {
    const url = BASE_API_URL + `/feature`
    const response = await apiService({
      url,
      method: "GET",
      params: {
        showdeleted: false,
        surveyId,
      },
      withCredentials: false,
    })
    const { data } = response
    return data
  }

  async search(
    time: string | Date,
    projectId: number,
    searchText?: string,
    formId?: number,
    status?: IFeatureFilterStatus,
    user?: string,
    surveyId?: number,
  ): Promise<IFeatureStyled[]> {
    const url = BASE_API_URL + `/feature/search`

    const response = await apiService.get(url, {
      withCredentials: false,
      params: {
        time,
        formId,
        searchText,
        projectId,
        status,
        user,
        surveyId,
      },
    })
    const { data } = response
    return data
  }

  async getGeoJSON(arg: {
    time: string | Date
    projectId: number
    searchText?: string
    formId?: number
    status?: IFeatureFilterStatus
    user?: string
    surveyId?: number
  }): Promise<FeatureCollection> {
    const url = BASE_API_URL + `/map/features/71NccqKKsb1PKc1sJH29omoLVclOvOEh/${arg.formId}/${arg.projectId}`
    const { projectId, searchText, formId, surveyId, time, user, status } = arg

    const response = await apiService.get(url, {
      withCredentials: false,
      params: {
        projectId,
        time,
        searchText,
        surveyId,
        user,
        formId,
        status,
        offset: dayjs().utcOffset(),
      },
    })
    const { data } = response
    return data
  }

  async getProperties(arg: {
    featureId: number
    time: string | Date
    projectId: number
    searchText?: string
    formId?: number
    status?: IFeatureFilterStatus
    user?: string
    surveyId?: number
  }): Promise<FeatureCollection> {
    const url = BASE_API_URL + `/map/features/71NccqKKsb1PKc1sJH29omoLVclOvOEh/${arg.formId}/${arg.projectId}`

    const response = await apiService.get(url, {
      withCredentials: false,
      params: {
        groupProps: true,
        featureId: arg.featureId,
      },
    })
    const { data } = response
    return data
  }

  async getGeometries(arg: {
    featureId: number
    time: string | Date
    projectId: number
    searchText?: string
    formId?: number
    status?: IFeatureFilterStatus
    user?: string
    surveyId?: number
  }): Promise<FeatureCollection> {
    const url = BASE_API_URL + `/map/features/71NccqKKsb1PKc1sJH29omoLVclOvOEh/${arg.formId}/${arg.projectId}`
    const { projectId, searchText, formId, featureId, surveyId, time, user, status } = arg

    const response = await apiService.get(url, {
      withCredentials: false,
      params: {
        projectId,
        time,
        featureId,
        searchText,
        surveyId,
        user,
        formId,
        groupProps: false,
        skipProps: true,
        status,
        offset: dayjs().utcOffset(),
      },
    })
    const { data } = response
    return data
  }

  async getUsers(projectId: number): Promise<string[]> {
    const url = BASE_API_URL + `/feature/users`

    const response = await apiService.get(url, {
      withCredentials: false,
      params: {
        projectId,
      },
    })
    const { data } = response
    return data
  }

  processFeature(p: IFeatureStyled | IFeatureStyled[]): GeoJsonObject | null {
    // Helper function to process a single IFeatureStyled object
    const processSingleFeature = (featureStyled: IFeatureStyled): GeoJsonObject => {
      const geoJson = JSON.parse(featureStyled.geoJson ?? "{}")
      if (
        !geoJson.geometry ||
        !geoJson.geometry.coordinates ||
        (geoJson.geometry.coordinates && geoJson.geometry.coordinates.length === 0)
      ) {
        return null
      }

      return {
        ...geoJson,
        properties: {
          color: featureStyled.color ?? "#3388ff",
          weight: featureStyled.weight ?? 2,
          opacity: featureStyled.opacity ?? 1,
          dashArray: featureStyled.dashArray ?? null,
          lineLabel: featureStyled.lineLabel ?? null,
          FeatureId: featureStyled.featureId,
          FeatureKey: featureStyled.id ?? "",
        },
      }
      // return {
      //   type: "Feature",
      //   // @ts-ignore
      //   geometry: geoJson,
      //   properties: {
      //     color: featureStyled.color ?? "#3388ff",
      //     weight: featureStyled.weight ?? 2,
      //     opacity: featureStyled.opacity ?? 1,
      //     dashArray: featureStyled.dashArray ?? null,
      //   },
      // }
    }

    // Check if p is an array
    if (Array.isArray(p)) {
      // Filter out elements without a geoJson property and process the remaining
      const features = p.filter((feature) => feature.geoJson).map(processSingleFeature)

      return {
        type: "FeatureCollection",
        // @ts-ignore
        features: features,
      }
    } else {
      // If p is a single object and has a geoJson property, process it
      if (p.geoJson) {
        return processSingleFeature(p)
      } else {
        // Handle the case where the single object doesn't have a geoJson property
        // This could be returning null or an empty object depending on your use case
        // @ts-ignore
        return null // or {} depending on how you want to handle this case
      }
    }
  }

  async getFeaturesStyled(payload: {
    projectId?: number
    featureId?: number
    surveyId?: number
  }): Promise<IFeatureStyled[]> {
    const url = BASE_API_URL + `/feature/feature-styled`
    const { projectId, featureId, surveyId } = payload
    const response = await apiService({
      url,
      method: "GET",
      params: {
        projectId,
        surveyId,
        featureId,
      },
      withCredentials: false,
    })
    const { data } = response
    return data
  }

  async getFeaturesQuickAdds(payload: { projectId?: number; surveyId?: number }): Promise<IFeatureStyled[]> {
    const url = BASE_API_URL + `/feature/quick-adds`
    const { projectId, surveyId } = payload
    const response = await apiService({
      url,
      method: "GET",
      params: {
        projectId,
        surveyId,
      },
      withCredentials: false,
    })
    const { data } = response
    return data
  }

  async getFeatureFeatures(featureId: number): Promise<IFeature[]> {
    const url = BASE_API_URL + `/feature/features/${featureId.toString()}`

    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
    })

    const { data } = response
    return data
  }

  async getFeatureImages(featureId: string): Promise<IFormAttachment[]> {
    const url = BASE_API_URL + `/feature/images`

    // TODO: Add method to get attachments

    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
      params: { featureId },
    })

    const { data } = response
    console.log(data)
    return data
  }

  async getFeatureAttachments(featureId: string): Promise<IFormAttachment[]> {
    const url = BASE_API_URL + `/feature/attachments`

    // TODO: Add method to get attachments

    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
      params: { featureId },
    })

    const { data } = response
    console.log(data)
    return data
  }

  async getFeatureAttachmentsByField(featureId: string, fieldId: string): Promise<IFormAttachment[]> {
    const url = BASE_API_URL + `/feature/attachments-by-field`

    // TODO: Add method to get attachments

    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
      params: { featureId, fieldId },
    })

    const { data } = response
    console.log(data)
    return data
  }

  async getFeatureAttachment(featureId: string, attachmentId: number): Promise<IFormAttachment[]> {
    const url = BASE_API_URL + `/feature/attachments`

    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
      params: { featureId, attachmentId },
    })

    const { data } = response
    console.log(data)
    return data
  }

  getFeatureAttachmentURL(featureId: string, attachmentId: number): string {
    const url = BASE_API_URL + `/feature/attachment`

    return `${url}?featureId=${featureId}&attachmentId=${attachmentId}`
  }

  // async getFeatureImages(featureId: string, fieldId: string): Promise<ISurveyFile[]> {
  //   const url = BASE_API_URL + `/feature/images/${featureId}/${fieldId}`

  //   const response = await apiService({
  //     url,
  //     method: "GET",
  //     withCredentials: false,
  //   })

  //   const { data } = response
  //   return data
  // }

  async uploadAttachments(payload: ISurveyFile[]) {
    const url = BASE_API_URL + "/feature/upload-images"

    const response = await apiService.post(url, payload, {
      withCredentials: false,
    })

    const { data } = response
    return data
  }

  async deleteImage(path: string): Promise<string> {
    const url = BASE_API_URL + "/feature/delete-image"
    const response = await apiService.delete(url, {
      params: {
        path,
      },
      withCredentials: false,
    })
    const { data } = response
    return data
  }

  async uploadImage(payload: IFormAttachment, featureId: string): Promise<IFormAttachment> {
    const url = BASE_API_URL + "/feature/upload"

    const response = await apiService.post(
      url,
      {
        ...payload,
        featureId,
      },
      {
        withCredentials: false,
      },
    )

    const { data }: { data: string } = response
    return {
      ...payload,
      name: payload.name,
      data: undefined,
      url: data,
    }
  }

  async uploadImages(payload: ISurveyFile[]) {
    const url = BASE_API_URL + "/feature/upload-images"

    const response = await apiService.post(url, payload, {
      withCredentials: false,
    })

    const { data } = response
    return data
  }

  // async deleteImage(featureFileId: string): Promise<string | null> {
  //   const url = BASE_API_URL + "/feature/delete-image/" + featureFileId
  //   const response = await apiService({
  //     url,
  //     method: "DELETE",
  //     withCredentials: false,
  //   })
  //   if (response.status === 200) {
  //     return "deleted"
  //   }
  //   return null
  // }

  async getFeature(id: number): Promise<IFeature> {
    const url = BASE_API_URL + "/feature/" + id.toString()
    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
    })
    const { data }: { data: IFeature } = response
    return data
  }

  async addFeature(payload: IFeatureStyled, form?: IFormTemplate): Promise<IFeatureStyled> {
    const url = BASE_API_URL + "/feature"
    const response = await apiService.post(
      url,
      {
        ...payload,
        formId: form ? form.formId : payload.formId,
        formData: form?.template,
      },
      {
        withCredentials: false,
      },
    )

    const { data }: { data: IFeatureStyled } = response

    return data
  }

  async addFeatureFeature(payload: IFeature): Promise<IFeature> {
    const url = BASE_API_URL + "/featurefeature"
    const response = await apiService.post(url, payload, {
      withCredentials: false,
    })

    const { data }: { data: IFeature } = response

    return data
  }

  async updateFeature(feature: IFeature | IFeatureStyled): Promise<IFeature> {
    const url = BASE_API_URL + "/feature"

    const response = await apiService.put(url, feature, {
      withCredentials: false,
    })
    const { data } = response

    return data
  }

  async updateFeatureMetadata(feature: IFeature | IFeatureStyled): Promise<IFeature> {
    const url = BASE_API_URL + "/feature/update-metadata"

    const response = await apiService.put(url, feature, {
      withCredentials: false,
    })
    const { data } = response

    return data
  }

  async updateFeatureStatus(featureId: number, status?: IFeatureStatus): Promise<any> {
    const url = BASE_API_URL + "/feature/update-status"

    const response = await apiService.put(
      url,
      {
        featureId,
        status,
      },
      {
        withCredentials: false,
      },
    )
    const { data } = response

    return data
  }

  async updateFeatureFormData(featureId: number, formData?: string): Promise<any> {
    const url = BASE_API_URL + "/feature/update-form-data"

    const response = await apiService.put(
      url,
      {
        featureId,
        formData,
      },
      {
        withCredentials: false,
      },
    )
    const { data } = response

    return data
  }

  async deleteFeature(id: number): Promise<string> {
    const url = BASE_API_URL + `/feature/${id}`

    const response = await apiService.delete(url, {
      withCredentials: false,
    })
    const { statusText } = response
    return statusText
  }

  getStatusColor(status: string | undefined, active: boolean | undefined): string {
    if (active) {
      return "#4fc3f7"
    }
    switch (status) {
      case "waiting":
        return "#aaa"
      case "started":
        return "#81c784"
      case "paused":
        return "#ffb74d"
      case "abandoned":
        return "#e57373"
      case "completed":
        return "#666"
      default:
        return "grey"
    }
  }

  getStatusThemeColor(
    status: string | undefined,
    active: boolean | undefined,
  ): OverridableStringUnion<
    "primary" | "secondary" | "default" | "error" | "info" | "success" | "warning",
    BadgePropsColorOverrides
  > {
    if (active) {
      return "info"
    }
    switch (status) {
      case "waiting":
        return "default"
      case "started":
        return "success"
      case "paused":
        return "warning"
      case "abandoned":
        return "error"
      case "completed":
        return "secondary"
      default:
        return "default"
    }
  }

  getStatusThemeColorAlt(status: string | undefined): any {
    // if (active) {
    //   return blue[500]
    // }
    switch (status) {
      case "waiting":
        return {
          color: grey[800],
          backgroundColor: grey[100],
          borderColor: grey[500],
        }
      case "started":
        return {
          color: green[800],
          backgroundColor: green[50],
          borderColor: green[500],
        }
      case "paused":
        return {
          color: grey[800],
          backgroundColor: grey[50],
          borderColor: grey[100],
        }
      case "abandoned":
        return {
          color: red[800],
          backgroundColor: red[50],
          borderColor: red[500],
        }
      case "completed":
        return {
          color: grey[100],
          backgroundColor: grey[700],
          borderColor: grey[500],
        }
      default:
        return {
          color: grey[600],
          backgroundColor: grey[100],
          borderColor: grey[800],
        }
    }
  }

  getStatusIcon(status: string | undefined, active: boolean | undefined) {
    if (active) {
      return "WifiTethering"
    }
    switch (status) {
      case "waiting":
        return "AccessTime"
      case "started":
        return "PlayCircle"
      case "paused":
        return "PauseCircle"
      case "completed":
        return "Archive"
      default:
        return "ListAltOutlined"
    }
  }
}

export const featureService = new FeatureService()
