import { AddLocationAltOutlined, CancelOutlined, ExpandMore, PinDropOutlined } from "@mui/icons-material"
import type { Theme } from "@mui/material"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Fab,
  Grow,
  Icon,
  List,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"

import { useNavigate, useLocation } from "react-router"
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css"

import { FormProvider, useForm } from "react-hook-form"
import type { Feature, FeatureCollection, Geometry, GeometryCollection, Properties } from "@turf/helpers"
import { featureCollection } from "@turf/helpers"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import type { ReactNode } from "react"
import React, { useCallback, useContext, useEffect, useRef, useState } from "react"

import type { FormDataObject, IAlert, IFeature, IFeatureStyled, IPoint, ISurvey } from "../../interfaces"
import {
  deviceService,
  featureService,
  geolocationService,
  pointService,
  surveyService,
  userService,
} from "../../services"
import AuditButton from "../../components/Organisms/Audit/AuditButton.component"
import "../../components/Atoms/UserLocation/UserLocation.styles.css"
import SettingsContext from "../../contexts/settings.context"
import useFeatureButtonStatus from "../../hooks/useFeatureButtonStatus"
import { SignalRContext } from "../../App"
import FeatureFormComponent from "../../components/Organisms/Features/FeatureForm.component"
import { pascalCaseToUnderscore, truncateString } from "../../utils/string.util"
import { hexToRGB } from "../../components/FormBuilder/utilities"
import { HiChevronLeft, HiOutlineCamera, HiOutlineClipboardDocument, HiOutlineMap } from "react-icons/hi2"
import { grey } from "@mui/material/colors"
import LayoutContext from "../../contexts/layout.context"
import { HiChevronDown } from "react-icons/hi"
import { MSAlert, SheetTopBar } from "../../components"
import LoadingButton from "@mui/lab/LoadingButton"
import { VscCloudDownload } from "react-icons/vsc"
import { hasNode } from "../../utils/data.util"
import { reportService } from "../../services/report.service"
import { featureEventService } from "../../services/featureEvent.service"
import MapRenderer from "../../components/Organisms/MapRenderer/MapRenderer"
import MapLayer from "../../components/Organisms/MapRenderer/MapLayer"
import type { Map } from "leaflet"
import { v4 } from "uuid"
import { UPDATE_SETTINGS } from "../../contexts/reducers/settings.reducer"
import { useWorkTypes } from "../../hooks/data/useWorkTypes"
import FeatureActionSheet from "../../components/Organisms/FeatureActionSheet/FeatureActionSheet.component"
import Modal from "../../components/Molecules/Modal/Modal.component"
import { useDebounceCallback } from "usehooks-ts"
import { Resizable } from "re-resizable"
import { getLineLength, getPolygonArea } from "../../utils/geo.util"
import FormSelectInput from "../../components/Atoms/FormSelectInput/FormSelectInput"

const FeatureDetail = (props: {
  featureId?: number
  feature?: IFeatureStyled
  survey?: ISurvey
  onSave?: () => void
  goBack?: () => void
}) => {
  const theme = useTheme()

  const location = useLocation()
  const { state } = location

  // TODO: Why do we need to pass and use a prop for the feature. Should we not load it fresh when the component is rendered? If the parent that loads this
  // passes a stale feature, then we will have stale data here.
  const [feature, setFeature] = useState<IFeatureStyled>()
  const [survey, setSurvey] = useState<ISurvey>(props.survey ?? (state.survey as ISurvey))
  const [featureId] = useState<number | undefined>(props.featureId)
  const [featureStyled, setFeatureStyled] = useState<IFeatureStyled>()
  const [points, setPoints] = useState<IPoint[]>([])
  const [open, setOpen] = React.useState(false)
  const [, setShowBottomSheet] = useState<boolean>(false)
  const [showAddPointButton, setShowAddPointButton] = useState<boolean>(false)
  const [isAddingPoint, setIsAddingPoint] = useState<boolean>(false)
  const { $workTypes } = useWorkTypes()
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const { settingsState, removeActiveFeature, setActiveSurvey, setActiveFeature, updateSettings } =
    useContext(SettingsContext)
  const [errors, setErrors] = useState<string[]>([])
  const { closeSidebar, closeActionSheet, openActionSheet } = useContext(LayoutContext)
  const actionButtonProps = useFeatureButtonStatus(feature)
  const [alert] = useState<IAlert>()
  const methods = useForm()
  const isParentSurveyActive = Boolean(survey && survey.surveyId === settingsState.activeSurvey?.surveyId)
  const navigate = useNavigate()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"))
  const addButtonRef = useRef<HTMLButtonElement>(null)
  const [editingPointId, setEditingPointId] = useState(null)
  const [pointsCollection, setPointsCollection] = useState<FeatureCollection>()
  const mapRef = useRef<Map>(null)
  const [mode, setMode] = useState<"Map" | "Form" | "Camera">("Map")
  const [zoomMap, setZoomMap] = useState(false)
  const [featureName, setFeatureName] = useState("")
  const [hasCameraNode, setHasCameraNode] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [surveys, setSurveys] = useState<ISurvey[]>([])

  dayjs.extend(utc)

  const fetchFeatures = async () => {
    if (survey && survey.surveyId) {
      const _surveyFeatures = await featureService.getFeaturesStyled({ surveyId: survey.surveyId })
      return _surveyFeatures
    }
  }

  const setActive = useCallback(async () => {
    if (
      (feature?.status === "started" || feature?.status === "waiting") &&
      settingsState.activeFeature?.featureId !== feature.featureId
    ) {
      setActiveFeature(feature, survey)
    }
  }, [feature])

  // useEffect(() => {
  //   console.log("FeatureDetail render caused by:", {
  //     feature,
  //     points,
  //     survey,
  //     settingsState,
  //     mode,
  //     isDragging,
  //     isSaving,
  //   })
  // }, [feature, points, survey, settingsState, mode, isDragging, isSaving])

  useEffect(() => {
    setActive() // Here we automatically activate this feature
  }, [setActive])

  useEffect(() => {
    return () => {
      if (mapRef.current) {
        mapRef.current.remove()
      }
    }
  }, [])

  useEffect(() => {
    if (feature?.formData) {
      const formData: FormDataObject[] = JSON.parse(feature.formData)
      const sectionsChildren = formData.flatMap((f) => f.children)
      sectionsChildren.forEach((input) => {
        methods.control.register(input.id, { required: input.required, value: input.value })
      })

      setHasCameraNode(hasNode(formData, "type", "camera"))
    }

    // return () => {
    //   handleOnCommitData()
    // }
  }, [feature?.formData])

  const fetchFeature = useCallback(async () => {
    if (featureId) {
      const _feature = await featureService.getFeature(featureId)
      if (_feature && _feature.formData) {
        const json: FormDataObject[] = JSON.parse(_feature.formData)

        const formData = JSON.stringify(
          json.map((section) => {
            return {
              ...section,
              children: section.children.map((field) => {
                return {
                  ...field,
                  targetName: "Feature",
                  targetRefId: _feature.featureId,
                }
              }),
            }
          }),
        )

        setFeature({
          ..._feature,
          formData,
        })
      } else {
        setFeature(_feature)
      }

      const _featureStyled = await featureService.getFeaturesStyled({
        featureId,
        projectId: settingsState.activeProject?.projectId ?? 0,
      })
      setFeatureStyled(
        _featureStyled && _featureStyled.length && _featureStyled.length > 0 ? _featureStyled[0] : undefined,
      )

      if (feature && feature.surveyId) {
        const _survey = await surveyService.getSurvey(feature.surveyId)
        setSurvey(_survey)
      }

      if (feature && feature.workTypeId) {
        const workTypeId = feature.workTypeId
        if (workTypeId) {
          const _workType = $workTypes?.find((work) => work.workTypeId === workTypeId)
          if (_workType) setShowAddPointButton(!_workType.locationRequired)
        } else {
          setShowAddPointButton(false)
        }
      }

      setFeatureName(_feature.featureName ?? "")

      // if navigation here was from the feature selector, then close the feature selector
      setShowBottomSheet(false)
    }
  }, [settingsState.activeProject?.projectId, isSaving, featureId, $workTypes])

  const fetchPoints = useCallback(async () => {
    const points = await pointService.getPoints({ featureId })
    if (points) {
      setPoints(points)
    }
  }, [featureId])

  useEffect(() => {
    if (featureId) {
      fetchFeature().then(() => {
        fetchPoints().then(() => {
          setZoomMap(true)
        })
      })
    }
  }, [])

  const fetchSurveys = useCallback(async () => {
    const surveys = await surveyService.search(
      "all",
      settingsState?.activeProject?.projectId ?? 0,
      "",
      -1,
      "in progress",
    )
    if (surveys) {
      setSurveys(surveys)
    }
  }, [])

  useEffect(() => {
    fetchSurveys()
  }, [fetchSurveys])

  // while on this page, display new points as they come in
  SignalRContext.useSignalREffect(
    "DeviceUpdate", // Your Event Key
    // @ts-ignore
    (payload: any) => {
      setPoints([...points, pointService.processPoint(payload)])
      fetchFeature()
    },
    [points],
  )

  const pointsToCollection = useCallback(() => {
    const pointsArray = points
      .filter((obj) => obj.geoFeature !== undefined)
      .map((f, index) => {
        const style = featureStyled
        return {
          ...f.geoFeature,
          properties: {
            ...f.geoFeature.properties,
            pointId: f.pointId,
            id: `point-${index}`,
            Icon: style ? style.icon : undefined,
            IconSize: style?.iconSize ? style.iconSize : undefined,
            IconColor: style?.iconColor ? style.iconColor : undefined,
            IconAnchor: style?.iconAnchor ? style.iconAnchor : undefined,
            PopupAnchor: style?.popupAnchor ? style.popupAnchor : undefined,
            displayProps: {
              "Serial Number": f.deviceSerialNumber ?? null,
              "Feature Date": f.createdAtDate ?? null,
              "Feature Name": f.featureName ?? null,
              "Surveyed By": f.createdBy ?? null,
            },
          },
        }
      })
    return featureCollection(pointsArray, {})
  }, [points, feature, featureStyled])

  useEffect(() => {
    setPointsCollection(pointsToCollection())
    //mapRef.current?.fitBounds(pointsCollection?.bbox)
  }, [points, pointsToCollection])
  // END: display points as they come in

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const renderStatusColor = (feature: IFeature | IFeatureStyled) => {
    return featureService.getStatusThemeColorAlt(feature.status)
  }

  const handleOnComplete = useCallback(async () => {
    try {
      console.log(
        "handleOnComplete",
        `Survey: ${survey && survey.surveyId}`,
        `Feature: ${feature && feature.featureId}`,
      )

      if (feature?.formData) {
        const formData: FormDataObject[] = JSON.parse(feature.formData)
        const sectionsChildren = formData.flatMap((f) => f.children)
        sectionsChildren.forEach((input) => {
          // Check is required
          if (input.required) {
            if (input.value === undefined || input.value === null) {
              methods.setError(input.id, { message: `${input.label} is required` })
              setErrors([...errors, input.id])
            } else if ((input.value as string) && (input.value as string).length === 0) {
              methods.setError(input.id, { message: `${input.label} is required` })
              setErrors([...errors, input.id])
            }
          }
        })

        console.log(errors)

        if (errors.length > 0) {
          // Form has errors so he cannot submit.
          setTimeout(() => {
            methods.clearErrors()
            setErrors([])
          }, 5000)
          return
        }

        // Update feature to "paused" in backend
        await featureService.updateFeatureStatus(feature.featureId ?? -1, "completed")
        // Remove active feature from global state
        removeActiveFeature()
        // Checks if the survey features are all completed
        // If true, we navigate back to the Survey Detail page
        // const features = await fetchFeatures()
        // if (features && features.filter((f) => f.status === "completed").length === features.length) {
        closeSidebar()
        navigate(`project/features`)
        // } else {
        //   // if there are non-completed feature forms, we go back to feature selector
        //   openSidebar(<FeatureSelectorSheet />)
        // }
        // Refresh list of survey features
        // from Survey detail page
        if (props.onSave) props.onSave()
      }
    } catch (error) {
      console.log(error)
    }
  }, [feature])

  const handleOnAbandon = useCallback(async () => {
    try {
      console.log("handleOnAbandon", `Survey: ${survey && survey.surveyId}`, `Feature: ${feature && feature.featureId}`)
      if (!feature) return
      // Update local feature state
      setFeature({
        ...feature,
        status: "abandoned",
      })

      // Remove active feature from user in backend
      await userService.updateUserFeature(-1)

      // Update feature to "paused" in backend
      await featureService.updateFeature({
        ...feature,
        status: "abandoned",
      })

      // Remove active feature from global state
      removeActiveFeature()
      closeSidebar()
      // Make sure list of features is updated in survey detail
      if (props.onSave) props.onSave()
    } catch (error) {
      console.error(
        error,
        "abandoned",
        `Survey: ${survey && survey.surveyId}`,
        `Feature: ${feature && feature.featureId}`,
      )
    }
  }, [feature])

  const handleOnStart = useCallback(
    async (restart: boolean = false) => {
      console.log(
        "handleOnStart",
        `Survey: ${survey && survey.surveyId}`,
        `Feature: ${feature && feature.featureId}`,
        `Restart: ${restart}`,
      )
      try {
        if (!feature) return
        // If parent survey is not active,
        // we need to make it active
        if (!isParentSurveyActive && survey) setActiveSurvey(survey)

        // Update User's active feature in Backend
        setActiveFeature(feature, survey)
        if (restart) {
          await featureEventService.addFeatureEvent({
            featureId: feature.featureId ?? -1,
            eventType: "restart",
          })
        }

        // Update local feature state
        setFeature({
          ...feature,
          status: "started",
        })

        // Update feature to "paused" in backend
        await featureService.updateFeature({
          ...feature,
          status: "started",
        })

        // if the status is active, then initate a scan
        if (settingsState.deviceSerialNumber && settingsState.deviceSerialNumber !== "") {
          await deviceService.scan(settingsState.deviceSerialNumber, dayjs.utc().add(-5, "minutes").format())
        }

        // Refresh list of survey features
        // from Survey detail page
        if (props.onSave) props.onSave()
      } catch (error) {
        console.error(
          error,
          "handleOnStart",
          `Survey: ${survey && survey.surveyId}`,
          `Feature: ${feature && feature.featureId}`,
        )
      }
    },
    [feature],
  )

  const handleOnAddPoint = () => {
    setIsAddingPoint(true)

    geolocationService
      .getCurrentLocation(async (position) => {
        if (!position) {
          setIsAddingPoint(false)
          return
        }
        if (position.coords) {
          var geoJson: GeoJSON.Point = {
            type: "Point",
            coordinates: [position.coords.longitude, position.coords.latitude],
          }

          const payload: Omit<IPoint, "geoFeature"> = {
            geoJson: JSON.stringify(geoJson),
            deviceId: -1,
            featureId: featureId,
            projectId: settingsState.activeProject?.projectId ?? -1,
          }

          const res = await pointService.addPoint(payload, "mobile")

          if (res) {
            setPoints((points) => [...points, pointService.processPoint(res)])
            setIsAddingPoint(false)
            fetchFeature()
          }
        }
      })
      .catch((error) => {
        console.error(error)
        setIsAddingPoint(false)
      })
  }

  const handleOnSave = useCallback(
    async (formData: string) => {
      // If user is trying to edit the form of a started feature that is not active.
      if (
        feature?.featureId !== settingsState.activeFeature?.featureId &&
        feature?.status !== "completed" &&
        feature?.status !== "started"
      ) {
        // TODO: Display dismissable notification that says the the feature is now active
        // then ...
        await setActive()
        return
      }
      if (feature?.featureId) {
        setFeature({ ...feature, formData })
        await featureService.updateFeatureFormData(feature.featureId, formData)
      }
    },
    [feature],
  )

  const debouncedOnSave = useDebounceCallback(handleOnSave, 1500)

  const updateFeatureApi = useCallback(
    async (newFeatureName: string) => {
      try {
        if (!feature?.featureId) return
        await featureService.updateFeatureMetadata({
          ...feature,
          featureName: newFeatureName,
        })
      } catch (error) {
        console.error("Failed to update feature name:", error)
      }
    },
    [feature?.featureId],
  )

  const debouncedUpdateApi = useDebounceCallback(updateFeatureApi, 1500)

  const changeFeatureName = (newFeatureName: string) => {
    if (!feature || !feature.featureId) return

    // Update UI immediately
    setFeature((prevFeature) => {
      if (!prevFeature) return prevFeature
      return {
        ...prevFeature,
        featureName: newFeatureName,
      }
    })
    setFeatureName(newFeatureName)

    // Only pass the new name to the debounced function
    debouncedUpdateApi(newFeatureName)
  }

  const changeFeatureSurvey = async (newSurveyId: number) => {
    try {
      if (!feature || !feature.surveyId) return
      await featureService.updateFeatureMetadata({
        ...feature,
        surveyId: newSurveyId,
      })

      setFeature({ ...feature, surveyId: newSurveyId })
    } catch (error) {
      console.error(error)
    }
  }

  // const handleOnCommitData = useCallback(async () => {
  //   try {
  //     await featureService.updateFeature({ ...feature, formData: feature.formData })
  //     if (feature.featureId) {
  //       await featureService.updateFeatureFormData(feature.featureId, feature.formData)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }, [feature])

  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleOnDownload = async (reportType) => {
    handleMenuClose()
    try {
      if (reportType === "pdf") {
        const reportBlob = await reportService.getFeatureReport({
          featureId,
          projectIds: [settingsState.activeProject?.projectId],
        })
      } else if (reportType === "zip") {
        const reportBlob = await reportService.getFeatureImagesDownloadForFeature({ featureId })
      }
    } catch (error) {
      console.error("Error downloading report:", error)
    }
  }

  const handleEditClick = (pointId: any) => {
    setEditingPointId(pointId)
  }

  // const handleDeleteFeatureClick = useCallback(async (feature: Feature<Geometry | GeometryCollection, Properties>) => {
  //   if (confirm("Are you sure you want to delete this feature?") && feature.properties && feature.properties.pointId) {
  //     try {
  //       const response = await pointService.deletePoint(feature.properties.pointId)
  //       console.log(response)
  //       await fetchFeature()
  //       await fetchFeatures()
  //       await fetchPoints()
  //       console.log("Feature refreshed successfully")
  //       const updatedPoints = points.filter((element: IPoint) => element.pointId !== feature.properties?.pointId)
  //       setPoints(updatedPoints)
  //       setEditingPointId(null)
  //       // closeSidebar()
  //       console.log("Point updated successfully")
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  // }, [])

  const handleDeletePointClick = useCallback(
    async (feature: Feature<Geometry | GeometryCollection, Properties>) => {
      if (confirm("Are you sure you want to delete this point?") && feature.properties && feature.properties.pointId) {
        try {
          const response = await pointService.deletePoint(feature.properties.pointId)
          console.log(response)
          await fetchFeatures()
          await fetchPoints()
          console.log("Feature refreshed successfully")
          const updatedPoints = points.filter((element: IPoint) => element.pointId !== feature.properties?.pointId)
          setPoints(updatedPoints)
          setEditingPointId(null)
          closeActionSheet()
          console.log("Point updated successfully")
        } catch (error) {
          console.log(error)
        }
      }
    },
    [points],
  )

  const handleOnMove = async (feature: Feature<Geometry | GeometryCollection, Properties>) => {
    setEditingPointId(feature.properties?.pointId)
    setIsDragging(true)
    closeActionSheet()
    // @ts-ignore
    if (feature.geometry && feature.geometry.coordinates && feature.geometry.coordinates.length > 1 && mapRef.current) {
      // @ts-ignore
      const lat = feature.geometry.coordinates[1] as number
      // @ts-ignore
      const long = feature.geometry.coordinates[0] as number
      mapRef.current.flyTo([lat, long])
    }
  }

  const handleOnFeatureSelect = (feature: Feature<Geometry | GeometryCollection, Properties>) => {
    openActionSheet(
      <FeatureActionSheet
        feature={feature}
        onDelete={async (feature) => handleDeletePointClick(feature)}
        onEdit={() => {}}
        onEnd={async (feature) => {
          handleOnFeatureEvent("end", feature)
        }}
        onMove={handleOnMove}
        onStart={async (feature) => {
          handleOnFeatureEvent("start", feature)
        }}
      />,
    )
  }

  const handleOnFeatureEvent = async (type: string, feature: Feature<Geometry | GeometryCollection, Properties>) => {
    if (
      feature !== undefined &&
      feature.properties !== undefined &&
      feature.properties?.pointId !== undefined &&
      feature.properties?.pointId !== undefined
    ) {
      await featureEventService.addFeatureEvent({
        featureId: feature.properties?.featureId ?? -1,
        pointId: feature.properties.pointId,
        eventType: type,
      })
    }
  }

  const saveNewMarkerPosition = async () => {
    if (mapRef.current) {
      setIsSaving(true)
      const newCenter = mapRef.current.getCenter()
      const newPoint = points.find((p) => p.pointId === editingPointId)
      if (newPoint) {
        console.log("Original point:", newPoint)

        newPoint.latitude = newCenter.lat
        newPoint.longitude = newCenter.lng
        if (
          newPoint.geoFeature &&
          newPoint.geoFeature.geometry &&
          (newPoint.geoFeature.geometry as GeoJSON.Point).coordinates
        ) {
          ;(newPoint.geoFeature.geometry as GeoJSON.Point).coordinates = [newCenter.lng, newCenter.lat]
        }

        console.log("Updated point:", newPoint)
        try {
          await pointService.updatePoint(newPoint)
          await fetchFeatures()
          setPoints((points) => {
            const pointIndex = points.findIndex((point) => point.pointId === newPoint.pointId)
            console.log(pointIndex)
            if (pointIndex >= 0) points[pointIndex] = newPoint
            return [...points]
          })
          setIsSaving(false)
          setIsDragging(false)
          console.log("Point updated successfully")
        } catch (error) {
          console.error("Error updating point:", error)
        }
      } else {
        console.log("No point found with pointId:", editingPointId)
      }
    }
    setEditingPointId(null)
  }

  //const renderMap = useCallback(() => {
  const renderMap = () => {
    return (
      <Box
        width={"100%"}
        height={"100%"}
        sx={{
          position: "relative",
          borderRadius: isDesktop ? 3 : 0,
          zIndex: 10,
          overflow: "hidden",
        }}>
        <MapRenderer
          key={feature?.featureId}
          showControls={false}
          onCancel={() => setIsDragging(false)}
          isSaving={isSaving}
          isDragging={isDragging}
          onDelete={() => {}}
          layerControlPosition="bottomleft"
          hideAddressSearch
          secondaryButton={
            !isDesktop && (
              <Stack gap={1} justifyContent="flex-end" alignItems="flex-end">
                {hasCameraNode && (
                  <Fab
                    color="secondary"
                    sx={{
                      boxShadow: "none",
                      backgroundColor: "white",
                      color: "black",
                      "&:hover": {
                        backgroundColor: theme.palette.grey[50],
                      },
                      "&.Mui-disabled": {
                        backgroundColor: theme.palette.grey[400],
                      },
                    }}
                    onClick={() => {
                      setMode("Form")
                      updateSettings(UPDATE_SETTINGS, {
                        ...settingsState,
                        cameraOpen: true,
                      })
                    }}>
                    <Stack gap={0.2} justifyContent="center" alignItems="center">
                      <HiOutlineCamera size={36} />
                    </Stack>
                  </Fab>
                )}
                <Fab
                  color="secondary"
                  sx={{
                    boxShadow: "none",
                    backgroundColor: "white",
                    color: "black",
                    "&:hover": {
                      backgroundColor: theme.palette.grey[50],
                    },
                    "&.Mui-disabled": {
                      backgroundColor: theme.palette.grey[400],
                    },
                  }}
                  onClick={() => setMode("Form")}>
                  <Stack gap={0.2} justifyContent={"center"} alignItems={"center"}>
                    <HiOutlineClipboardDocument size={24} />
                    <Typography lineHeight={1.1} fontWeight={"bold"} fontSize={12} textTransform={"capitalize"}>
                      Form
                    </Typography>
                  </Stack>
                </Fab>
              </Stack>
            )
          }
          onSave={async () => saveNewMarkerPosition()}
          showCurrentLocationButton
          ref={mapRef}>
          {[
            pointsCollection && (
              <MapLayer
                key="surveyPoints"
                layerId={v4()}
                layerName="Survey Points"
                isDragging={isDragging}
                data={pointsCollection as FeatureCollection}
                onSelect={() => {}}
                onDragStart={(feature) => {
                  console.log(feature)
                  setEditingPointId(feature.properties?.pointId)
                  setIsDragging(true)
                }}
                onDragEnd={() => setIsDragging(false)}
                onFeatureSelect={handleOnFeatureSelect}
                onFeatureEvent={handleOnFeatureEvent}
                onEdit={handleEditClick}
                onDelete={async (point) => {
                  await handleDeletePointClick(point)
                }}
                hiddenPointId={editingPointId}
                zoomMap={zoomMap}
                setZoomMap={(value: boolean) => setZoomMap(value)}
              />
            ),
            feature && feature.geoJson && feature.featureTypes && feature.featureTypes !== "Point" && (
              <MapLayer
                key={feature.featureId?.toString() as string}
                layerId={feature.featureId?.toString() as string}
                layerName={feature.featureName}
                data={featureService.processFeature(feature)}
                onSelect={() => {}}
                isDragging={isDragging}
                onEdit={() => {}}
                onDragStart={(feature) => {
                  console.log(feature)
                  setEditingPointId(feature.properties?.pointId)
                  setIsDragging(true)
                }}
                onDragEnd={() => setIsDragging(false)}
                hiddenPointId={null}
                zoomMap={zoomMap}
                onFeatureSelect={handleOnFeatureSelect}
                setZoomMap={(value: boolean) => setZoomMap(value)}
              />
            ),
          ]}
        </MapRenderer>
        <Tooltip ref={addButtonRef} title={"Add a Point"}>
          <span>
            <Fab
              color="secondary"
              sx={{
                boxShadow: "none",
                position: "absolute",
                bottom: 20,
                left: "50%",
                zIndex: 995,
                transform: "translateX(-50%)",
                "&.Mui-disabled": {
                  backgroundColor: theme.palette.grey[400],
                },
              }}
              onClick={handleOnAddPoint}>
              {isAddingPoint ? <CircularProgress sx={{ color: "white" }} size={24} /> : <AddLocationAltOutlined />}
            </Fab>
          </span>
        </Tooltip>
      </Box>
    )
  }
  //}, [pointsCollection, survey, feature, isSaving, isDragging, isAddingPoint, editingPointId, zoomMap])

  const renderAlert = () => <MSAlert alert={alert} />

  const renderStatusMenuButton = (trigger: ReactNode) => {
    return (
      <React.Fragment>
        <ButtonGroup
          variant="contained"
          disableElevation
          color={feature?.status !== "completed" ? actionButtonProps.color : "inherit"}>
          {trigger}
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal>
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper sx={{ borderRadius: 2, marginTop: -1 }}>
                {actionButtonProps.text === "completed" ||
                  (actionButtonProps.text === "abandoned" && (
                    <Stack
                      sx={{ borderBottom: `solid 1px ${theme.palette.divider}` }}
                      alignItems={"center"}
                      direction={"row"}
                      gap={1}
                      px={2}
                      py={1}>
                      <Typography fontSize={12} color={"black"}>
                        Change status
                      </Typography>
                    </Stack>
                  ))}

                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {actionButtonProps.actions.includes("started") && (
                      <MenuItem dense onClick={async () => handleOnStart()}>
                        <Stack gap={1} alignItems={"center"} direction={"row"}>
                          <Box width={12} height={12} bgcolor={theme.palette.success.main} borderRadius={"50%"} />
                          <ListItemText sx={{ color: theme.palette.success.main }}>In Progress</ListItemText>
                        </Stack>
                      </MenuItem>
                    )}

                    {actionButtonProps.actions.includes("completed") && (
                      <MenuItem dense onClick={async () => handleOnComplete()}>
                        <Stack gap={1} alignItems={"center"} direction={"row"}>
                          <Box width={12} height={12} bgcolor={grey[800]} borderRadius={"50%"} />
                          <ListItemText sx={{ color: grey[800] }}>Completed</ListItemText>
                        </Stack>
                      </MenuItem>
                    )}

                    {/* {actionButtonProps.actions?.includes("abandoned") && (
                      <MenuItem dense onClick={async () => handleOnAbandon()} sx={{ color: theme.palette.error.main }}>
                        <Stack gap={1} alignItems={"center"} direction={"row"}>
                          <Box width={12} height={12} bgcolor={theme.palette.error.main} borderRadius={"50%"} />
                          <ListItemText color="error">Abandon</ListItemText>
                        </Stack>
                      </MenuItem>
                    )} */}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
    )
  }

  const renderOptions = () => (
    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
      {props.goBack && (
        <Button
          variant="outlined"
          sx={{ textTransform: "capitalize", borderRadius: 24, px: 2 }}
          startIcon={<HiChevronLeft />}
          onClick={() => {
            if (props.goBack) props.goBack()
          }}>
          Back
        </Button>
      )}
      <Button
        startIcon={<PinDropOutlined />}
        sx={{ textTransform: "capitalize", borderRadius: 24, px: 2 }}
        onClick={() => {
          navigate("/project/points")
          closeSidebar()
        }}>
        Points
      </Button>

      <Stack direction={"row"} alignItems={"center"}>
        <AuditButton objectName={"Feature"} objectId={featureId ?? -1} />
        <Button
          onClick={handleMenuOpen}
          disableElevation
          sx={{ borderRadius: 24, px: 2, textTransform: "capitalize" }}
          startIcon={<VscCloudDownload />}>
          Report
        </Button>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={async () => handleOnDownload("pdf")}>PDF Export</MenuItem>
          <MenuItem onClick={async () => handleOnDownload("zip")}>Image Export ZIP</MenuItem>
        </Menu>
        {showAddPointButton && (
          <LoadingButton
            color="secondary"
            sx={{ borderRadius: 24, color: "white", textTransform: "capitalize" }}
            disabled={!showAddPointButton}
            startIcon={<AddLocationAltOutlined />}
            onClick={handleOnAddPoint}
            disableElevation
            loading={isAddingPoint}
            variant="contained">
            Point
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  )

  const renderDetail = () => {
    let geometryType = null
    let geometryMeasure = null

    if (feature?.geoJson) {
      const geoJson = JSON.parse(feature.geoJson) as Feature

      if (geoJson.geometry.type === "LineString" || geoJson.geometry.type === "MultiLineString") {
        geometryType = "Length (ft)"
        geometryMeasure = getLineLength(geoJson)
      } else if (geoJson.geometry.type === "Polygon" || geoJson.geometry.type === "MultiPolygon") {
        geometryType = "Area (sqft)"
        geometryMeasure = getPolygonArea(geoJson)
      }
    }

    return (
      <Accordion
        style={{ borderRadius: 12, marginTop: 12, overflow: "hidden" }}
        disableGutters
        elevation={0}
        onChange={async (event, expanded) => {
          if (expanded) await setActive()
        }}
        sx={{ border: `solid 1px ${theme.palette.divider}` }}>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography sx={{ opacity: 0.8 }} fontWeight={700} typography={"body2"} color={theme.palette.text.secondary}>
            Detail
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: 0 }}>
          <Stack gap={2}>
            <Box>
              <Stack gap={2}>
                <Stack gap={0}>
                  <TextField
                    variant="outlined"
                    label="Name"
                    type="text"
                    autoComplete="off"
                    fullWidth
                    value={featureName}
                    size="small"
                    sx={{
                      backgroundColor: theme.palette.background.paper,
                    }}
                    onChange={async (e) => changeFeatureName(e.target.value)}
                  />
                </Stack>

                <Stack gap={0}>
                  <Typography sx={{ opacity: 0.7 }} typography="caption">
                    Survey
                  </Typography>
                  <Select
                    labelId="form-status-label"
                    id="form-status-select"
                    size="small"
                    fullWidth
                    input={<FormSelectInput isLoading={surveys.length === 0} label={"Survey"} />}
                    value={feature?.surveyId ?? ""}
                    label="Survey"
                    renderValue={(surveyId) => surveys.find((f) => f.surveyId === surveyId)?.surveyName ?? ""}
                    onChange={async (event) => changeFeatureSurvey(event.target.value as number)}>
                    {surveys &&
                      surveys
                        .sort((a, b) => (a.surveyName ?? "").localeCompare(b.surveyName ?? ""))
                        .map((survey, index) => {
                          return (
                            <MenuItem key={index} value={survey.surveyId}>
                              <Stack>
                                <Typography fontSize={14}>{survey.surveyName ?? ""}</Typography>
                                <Typography fontSize={10} color={theme.palette.grey[700]}>
                                  {survey.formTitle} | <i>{survey.workTypeName}</i>
                                </Typography>
                              </Stack>
                            </MenuItem>
                          )
                        })}
                  </Select>
                </Stack>

                <Stack gap={0}>
                  <Typography sx={{ opacity: 0.7 }} typography="caption">
                    Work Type
                  </Typography>
                  <Typography>
                    <i>{feature?.workTypeName}</i>
                  </Typography>
                </Stack>

                {geometryType && (
                  <Stack gap={0}>
                    <Typography sx={{ opacity: 0.7 }} typography="caption">
                      {geometryType}
                    </Typography>
                    <Typography>{geometryMeasure?.toLocaleString()}</Typography>
                  </Stack>
                )}

                <Divider />
                {feature && feature.createdBy && (
                  <Stack>
                    <Typography typography={"caption"} fontWeight={"bold"}>
                      Created By
                    </Typography>

                    <Typography typography={"body2"}>{feature.createdBy}</Typography>
                  </Stack>
                )}

                <Stack direction={"row"} spacing={8} justifyContent="space-between">
                  <Stack direction={"column"}>
                    <Typography typography={"caption"} fontWeight={"bold"}>
                      Created At
                    </Typography>
                    {feature && feature.createdAt ? (
                      <Typography typography={"body2"}>{new Date(feature.createdAt).toDateString()}</Typography>
                    ) : (
                      <Typography fontStyle={"italic"} sx={{ opacity: 0.5 }} typography={"body2"}>
                        {"Empty"}
                      </Typography>
                    )}
                  </Stack>

                  {actionButtonProps.actions?.includes("abandoned") && (
                    <>
                      <Button
                        size="small"
                        onClick={async () => {
                          console.log("abandoned")
                          setOpenConfirmationModal(true)
                        }}
                        sx={{
                          color: theme.palette.error.main,
                          width: "fit-content",
                          fontSize: 12,
                          textTransform: "capitalize",
                        }}
                        variant="text">
                        <Stack gap={1} alignItems={"center"} direction={"row"}>
                          <CancelOutlined fontSize="small" />
                          <ListItemText color="error">Abandon</ListItemText>
                        </Stack>
                      </Button>
                      <Modal
                        open={openConfirmationModal}
                        onClose={() => setOpenConfirmationModal(false)}
                        setOpen={setOpenConfirmationModal}
                        title="Abandon Feature"
                        onSuccess={() => {
                          setOpenConfirmationModal(false)
                          handleOnAbandon()
                          closeSidebar()
                        }}
                        successBtnText="Abandon"
                        successBtnColor="error"
                        content={
                          <Box>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                              Abandon "{feature?.featureName}" Feature
                            </Typography>
                            <Divider />
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                              Are you sure you want to abandon this Feature?
                            </Typography>
                          </Box>
                        }
                      />
                    </>
                  )}
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
    )
  }

  const renderFeatureForm = () => (
    <Box position={"relative"}>
      <Box my={1} style={{ borderRadius: 4, overflow: "hidden" }} position={"relative"}>
        {feature?.status !== "started" && (
          <Box
            bgcolor={theme.palette.grey[600]}
            sx={{
              opacity: 0.15,
              zIndex: 1,
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}>
            <Typography sx={{ transform: "rotate(45deg)" }} fontWeight={900} letterSpacing={3} fontSize={48}>
              READ ONLY
            </Typography>
          </Box>
        )}
        {feature !== undefined && (
          <FeatureFormComponent
            onSave={debouncedOnSave}
            readOnly={actionButtonProps.readOnly || feature?.status === "completed"}
            feature={feature}
          />
        )}
      </Box>
      {!isDesktop && (
        <Tooltip ref={addButtonRef} title={feature?.formTitle}>
          <span>
            <Fab
              color="secondary"
              sx={{
                boxShadow: "none",
                position: "fixed",
                bottom: 20,
                right: mode === "Form" ? "18px" : "50%",
                zIndex: 995,
                transitionProperty: "right",
                transitionDuration: 0.4,
                transform: mode === "Form" ? "translateX(0)" : "translateX(50%)",
                "&.Mui-disabled": {
                  backgroundColor: theme.palette.grey[400],
                },
              }}
              onClick={() => {
                setZoomMap(true)
                setMode("Map")
              }}>
              <HiOutlineMap size={28} />
            </Fab>
          </span>
        </Tooltip>
      )}
    </Box>
  )

  // @ts-ignore
  return (
    <Box position={"relative"}>
      <Stack>
        <SheetTopBar
          onClose={() => closeSidebar()}
          avatar={
            <Avatar
              sx={{
                bgcolor: featureService.getStatusColor(
                  feature?.status,
                  settingsState.activeFeature && settingsState.activeFeature.featureId === feature?.featureId,
                ),
                mr: "5px",
              }}>
              <Icon>
                {pascalCaseToUnderscore(
                  featureService.getStatusIcon(
                    feature?.status,
                    settingsState.activeFeature && settingsState.activeFeature.featureId === feature?.featureId,
                  ),
                )}
              </Icon>
            </Avatar>
          }
          leading={
            <Stack gap={0}>
              <Typography color={"white"} style={{ opacity: 0.7 }} typography="caption">
                {feature?.formTitle}
              </Typography>
              <Typography color={"white"} fontWeight={900} typography={isDesktop ? "normal" : "caption"}>
                {isDesktop ? feature?.featureName : truncateString(feature?.featureName ?? "", 10)}
              </Typography>
            </Stack>
          }
          trailing={
            <Stack gap={1} alignItems={"center"} flexDirection={"row"}>
              {settingsState.activeFeature && settingsState.activeFeature?.featureId === feature?.featureId && (
                <Chip
                  size="small"
                  sx={{
                    backgroundColor: hexToRGB(theme.palette.info.main, 0.1),
                    color: "white",
                  }}
                  label="Active"
                />
              )}
              {feature &&
                renderStatusMenuButton(
                  <Chip
                    size="small"
                    clickable
                    ref={anchorRef}
                    sx={{
                      backgroundColor: renderStatusColor(feature).backgroundColor,
                      color: renderStatusColor(feature).color,
                      borderColor: renderStatusColor(feature).borderColor,
                      borderWidth: 1,
                      borderStyle: "solid",
                      textTransform: "capitalize",
                    }}
                    onClick={async () => {
                      handleToggle()
                    }}
                    label={
                      <Stack gap={1} alignItems={"center"} direction={"row"}>
                        <Typography fontSize={12}>
                          {feature && feature.status === "waiting" ? "Change status" : actionButtonProps.text}
                        </Typography>
                        <HiChevronDown />
                      </Stack>
                    }
                  />,
                )}
            </Stack>
          }
        />

        <FormProvider {...methods}>
          {renderAlert()}
          <Stack position={"relative"} gap={1} p={isDesktop || mode === "Form" ? 3 : 0}>
            <Typography variant={"body2"} sx={{ color: "grey.600", fontSize: "0.75rem", marginLeft: "auto", mt: -2 }}>
              ID: {feature?.featureId ?? "N/A"}
            </Typography>
            {(isDesktop || mode === "Form") && renderOptions()}
            {isDesktop && (
              // <Resizable style={{ width: "100%", position: "absolute", height: 350 }}>{renderMap()}</Resizable>
              <Box position={"relative"} width={"100%"} height={460}>
                {renderMap()}
              </Box>
            )}
            {!isDesktop && mode === "Map" ? (
              <Box position={"absolute"} width={"100%"} height={`calc(100dvh - 72px)`}>
                {renderMap()}
              </Box>
            ) : (
              <Stack>
                <List>{renderDetail()}</List>
                {renderFeatureForm()}
              </Stack>
            )}
          </Stack>
        </FormProvider>
      </Stack>
    </Box>
  )
}

export default FeatureDetail
