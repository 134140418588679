import type { OutlinedInputProps } from "@mui/material"
import { Box, CircularProgress, OutlinedInput } from "@mui/material"

const FormSelectInput = ({ isLoading, ...props }: { isLoading: boolean } & OutlinedInputProps) => {
  return (
    <OutlinedInput
      startAdornment={
        isLoading ? (
          <Box display={"flex"}>
            <CircularProgress size={16} />
          </Box>
        ) : null
      }
      {...props}
    />
  )
}

export default FormSelectInput
