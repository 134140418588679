import React, { useCallback } from "react"
import Checkbox from "@mui/material/Checkbox"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import type { FieldDataOptions, FormDataTypeProps } from "../../../interfaces"
import SettingsContext from "../../../contexts/settings.context"
import { formService } from "../../../services"
import { v4 } from "uuid"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface AutoCompleteOption {
  label: string
  value: string | number
}

const AutoCompleteInput: React.FC<FormDataTypeProps & { onSelection?: (value: string[]) => void }> = ({
  onSelection,
  onDataChange,
  dataUrl,
  ...props
}: FormDataTypeProps & {
  onSelection?: (value: string[]) => void
}) => {
  const [options, setOptions] = React.useState<AutoCompleteOption[]>([])
  const [loading, setLoading] = React.useState(false)
  const { settingsState } = React.useContext(SettingsContext)
  const [value, setValue] = React.useState<AutoCompleteOption[]>([])
  const [searchText, setSearchText] = React.useState("")

  const formatOptions = useCallback((data: FieldDataOptions) => {
    console.log(data)
    const mappedOptions = Object.entries(data).map(([, option]) => ({
      label: option.value,
      value: option.pkey ? String(option.pkey) : v4(),
    }))
    return mappedOptions.sort((a, b) => a.label.localeCompare(b.label))
  }, [])

  const formatValue = React.useCallback(
    (value: string[] | number[]): AutoCompleteOption[] => {
      if (value[0] === null) {
        return []
      }
      return value.map((val) => {
        return {
          label: options?.find((option) => option.value === String(val))?.label ?? String(val),
          value: String(val),
        }
      })
    },
    [props.value, options],
  )

  const loadOptions = React.useCallback(async () => {
    if (dataUrl) {
      console.log("fetching options...")
      setLoading(true)
      try {
        if (settingsState && settingsState.activeProject && settingsState.activeProject.projectId) {
          const response = await formService.getOptions(dataUrl, settingsState.activeProject.projectId)
          const formatted = formatOptions(response)
          if (response) setOptions(formatted)
          setLoading(false)
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      if (props.options) setOptions(formatOptions(props.options))
    }
  }, [dataUrl, settingsState, searchText])

  React.useEffect(() => {
    loadOptions()
  }, [loadOptions])

  React.useEffect(() => {
    if (props.value && options && options.length > 0) {
      console.log("setting value")
      setValue(formatValue(props.value))
    }
  }, [props.value, formatValue, options])

  return (
    <Autocomplete
      multiple
      isOptionEqualToValue={(option, value) => option.value === value.value}
      id="checkboxes-tags"
      readOnly={options === undefined}
      options={options.length > 32 ? options.slice(0, 32) : options}
      disableCloseOnSelect
      value={value}
      loading={loading}
      onChange={(e, value) => {
        setValue([...value])
        onDataChange(
          props.id,
          value.map((val) => String(val.value)),
        )
        if (onSelection) onSelection(value.map((val) => String(val.value)))
      }}
      size="small"
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props} key={option.value}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option.label}
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => setSearchText(e.target.value)}
          label={props.label}
          placeholder={props.placeholder}
        />
      )}
    />
  )
}

export default AutoCompleteInput
