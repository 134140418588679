import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Box, Button, Stack, Tab, Tabs, Typography, useTheme, type Theme, useMediaQuery } from "@mui/material"
import type {
  Feature,
  FeatureCollection,
  Geometry,
  GeometryCollection,
  LineString,
  MultiLineString,
  Properties,
} from "@turf/helpers"
import Lightbox from "yet-another-react-lightbox"
import { Captions, Thumbnails, Zoom } from "yet-another-react-lightbox/plugins"
import type { FormDataObject, IFeature, IFeatureStyled, IFormAttachment } from "../../../interfaces"
import { ImCompass } from "react-icons/im"
import { hexToRGB } from "../../FormBuilder/utilities"
import * as turf from "@turf/turf"

import { styled } from "@mui/material/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import { featureService, pointService } from "../../../services"
import { DateFieldInput, NumberFieldInput, TextFieldInput, TimeFieldInput } from "../../FormBuilder/inputs"
import { v4 } from "uuid"
import SheetTopBar from "../../Atoms/SheetTopBar/SheetTopBar.component"
import LayoutContext from "../../../contexts/layout.context"
import { useAlert } from "react-alert"
import { useSessionStorage } from "usehooks-ts"
import SettingsContext from "../../../contexts/settings.context"
import FormRenderer from "../../FormBuilder/FormRenderer"
import PointDataTable from "./PointDataTable.component"
import { getLineLength, getPolygonArea } from "../../../utils/geo.util"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

interface IFeatureProperties {
  type: string
  geometry: {
    type: string
    coordinates: number[]
  }
  properties: {
    metadata: Record<string, any>
    pointData: Record<string, any>
    formData: string
    images: string[]
  }
}

const filterProperties = (properties: any, excludedProps: string[]) => {
  return Object.fromEntries(Object.entries(properties).filter(([key]) => !excludedProps.includes(key)))
}

function FeatureProperties({
  feature,
  isEditing,
  excludedProps = [],
}: {
  feature: Feature
  isEditing: boolean
  excludedProps?: string[]
}) {
  const theme = useTheme()
  const [openLightBox, setOpenLightBox] = React.useState(false)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [images, setImages] = useState<IFormAttachment[]>([])
  const [formData, setFormData] = useState<FormDataObject[]>([])
  const [dbFeature, setDBFeature] = useState<IFeature>()
  const [displayProps, setDisplayProps] = useState<IFeatureProperties>()
  const { closeSidebar } = useContext(LayoutContext)
  const { settingsState } = useContext(SettingsContext)
  const { activeSurvey, activeProject } = settingsState
  const [value, setValue] = React.useState(0)
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"))

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const alert = useAlert()

  useEffect(() => {
    ;(async () => {
      try {
        console.log("feature", feature)
        if (feature.properties?.pointId !== undefined) {
          const response = await pointService.getProperties({
            pointId: Number(feature.properties?.pointId),
            time: settingsState.featuresFilter.selectedTimeFilter,
            projectId: activeProject?.projectId ?? -1,
            formId: settingsState.featuresFilter.selectedForm,
            getImages: true,
          })
          console.log(response)
          if (response && response.features && response.features.length > 0) {
            const props = response.features[0]
            setDisplayProps(props as unknown as IFeatureProperties)
          }
        } else if (feature.properties?.FeatureId !== undefined) {
          const response = await featureService.getProperties({
            featureId: Number(feature.properties?.FeatureId),
            projectId: activeProject?.projectId ?? -1,
            formId: settingsState.featuresFilter.selectedForm,
            getImages: true,
          })
          console.log(response)
          if (response && response.features && response.features.length > 0) {
            const detailedFeature = response.features[0]

            if (detailedFeature.geometry.type === "LineString" || detailedFeature.geometry.type === "MultiLineString") {
              var lengthInFeet = getLineLength(detailedFeature)
              // Add the length to feature properties
              if (detailedFeature.properties && !detailedFeature.properties.metadata) {
                detailedFeature.properties = {
                  ...detailedFeature.properties,
                  "Length (ft)": lengthInFeet,
                }
              } else if (detailedFeature.properties && detailedFeature.properties.metadata) {
                detailedFeature.properties = {
                  ...detailedFeature.properties,
                  metadata: {
                    ...detailedFeature.properties.metadata,
                    "Length (ft)": lengthInFeet,
                  },
                }
              }
            } else if (
              detailedFeature.geometry.type === "Polygon" ||
              detailedFeature.geometry.type === "MultiPolygon"
            ) {
              var areaInSqft = getPolygonArea(detailedFeature)
              // Add the area to feature properties
              if (detailedFeature.properties && !detailedFeature.properties.metadata) {
                detailedFeature.properties = {
                  ...detailedFeature.properties,
                  "Area (sqft)": areaInSqft,
                }
              } else if (detailedFeature.properties && detailedFeature.properties.metadata) {
                detailedFeature.properties = {
                  ...detailedFeature.properties,
                  metadata: {
                    ...detailedFeature.properties.metadata,
                    "Area (sqft)": areaInSqft,
                  },
                }
              }
            }

            setDisplayProps(detailedFeature as unknown as IFeatureProperties)
          }
        }
      } catch (error) {
        console.error(error)
      }
    })()
  }, [])

  useEffect(() => {
    if (feature.properties && feature.properties.featureId) {
      ;(async () => {
        const response = await featureService.getFeature(Number(feature.properties?.featureId))

        setDBFeature(response)
        console.log("useEffect > feature > response", response)
        if (response.formData && JSON.parse(response.formData)) {
          setFormData(JSON.parse(response.formData))
        }
      })()
    }
  }, [feature.properties])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }))

  // const handleOnSave = useCallback(async () => {
  //   try {
  //     if (!feature.properties) return

  //     const _point = await pointService.getPoint(feature.properties.pointId)
  //     // Update data for (Point)
  //     await pointService.updatePoint({
  //       ..._point,
  //       createdAtDate: String(displayedProps["Feature Date"]),
  //       createdBy: String(displayedProps["Surveyed By"]),
  //       deviceSerialNumber: String(displayedProps["Serial Number"]),
  //     })
  //     if (dbFeature) {
  //       console.log("response", dbFeature)
  //       const res = await featureService.updateFeature({
  //         ...dbFeature,
  //         featureName: String(displayedProps["Feature Name"]),
  //         formData: JSON.stringify(formData),
  //       })
  //     }

  //     navigate(`${pathname}`)
  //     closeSidebar()
  //     alert.show("Feature updated successfully", { type: "success" })
  //   } catch (error) {
  //     alert.show(error as string, { type: "error" })
  //     console.log(error)
  //   }
  // }, [displayedProps])

  const handleOnDelete = async () => {
    try {
      if (!feature.properties) return
      await featureService.deleteFeature(feature.properties.featureId)
      alert.show("Feature deleted successfully", { type: "success" })
      closeSidebar()
    } catch (error) {
      console.log(error)
    }
  }

  const handleOnChange = async (inputId: string, key: string, value: any) => {
    setDisplayProps((prevState) => {
      if (prevState) prevState.properties.formData[key] = value
      return prevState
    })
    // saving form data values on change.
    if (dbFeature && dbFeature.formData) {
      const inputIndex = formData[0].children.findIndex((input) => input.id === inputId)
      if (inputIndex > -1) {
        setFormData((prevState) => {
          prevState[0].children[inputIndex].value = value
          return [...prevState]
        })
      }
    }
  }

  const renderTabs = () => (
    <Tabs
      value={value}
      onChange={handleChange}
      variant="fullWidth"
      sx={{
        minHeight: 32,
        "& .MuiTabs-indicator": {
          height: 2,
        },
        "& .MuiTabs-scrollButtons": {
          width: 20,
          "&.Mui-disabled": {
            opacity: 0.3,
          },
        },
      }}>
      <Tab
        sx={{
          fontSize: 13, // Reduced from 14
          fontWeight: 700,
          textTransform: "capitalize",
          padding: "0 8px", // Added horizontal padding
          minHeight: 32,
          minWidth: 72, // Reduced minimum width
          "&.MuiTab-root": {
            [theme.breakpoints.down("sm")]: {
              fontSize: 12,
              minWidth: 60,
            },
          },
        }}
        label="General"
      />
      <Tab
        sx={{
          fontSize: 13, // Reduced from 14
          fontWeight: 700,
          textTransform: "capitalize",
          padding: "0 8px", // Added horizontal padding
          minHeight: 32,
          minWidth: 72, // Reduced minimum width
          "&.MuiTab-root": {
            [theme.breakpoints.down("sm")]: {
              fontSize: 12,
              minWidth: 60,
            },
          },
        }}
        label="Form"
      />
      {displayProps && displayProps.properties.pointData && (
        <Tab
          sx={{
            fontSize: 13, // Reduced from 14
            fontWeight: 700,
            textTransform: "capitalize",
            padding: "0 8px", // Added horizontal padding
            minHeight: 32,
            minWidth: 72, // Reduced minimum width
            "&.MuiTab-root": {
              [theme.breakpoints.down("sm")]: {
                fontSize: 12,
                minWidth: 60,
              },
            },
          }}
          label="Point"
        />
      )}
      {displayProps && displayProps.properties.images && displayProps.properties.images.length > 0 && (
        <Tab
          sx={{
            fontSize: 13, // Reduced from 14
            fontWeight: 700,
            textTransform: "capitalize",
            padding: "0 8px", // Added horizontal padding
            minHeight: 32,
            minWidth: 72, // Reduced minimum width
            "&.MuiTab-root": {
              [theme.breakpoints.down("sm")]: {
                fontSize: 12,
                minWidth: 60,
              },
            },
          }}
          label="Images"
        />
      )}
    </Tabs>
  )

  const renderPropertyInput = (key: string, value: any) => {
    if (key.toLowerCase().includes("photo")) {
      return <></>
    } else if (value instanceof Number) {
      return (
        <Stack gap={0.5}>
          <NumberFieldInput
            onDataChange={async (id, value) => handleOnChange(id, key, value)}
            children={[]}
            name={key}
            required={false}
            type="number"
            label={key}
            isEditing
            id={v4()}
            value={value}
          />
        </Stack>
      )
    } else if (key.toLowerCase().includes("date")) {
      return (
        <Stack gap={0.5}>
          <DateFieldInput
            onDataChange={async (id, value) => handleOnChange(id, key, value)}
            children={[]}
            name={key}
            required={false}
            type="date"
            label={key}
            isEditing
            id={v4()}
            value={value}
          />
        </Stack>
      )
    } else if (key.toLowerCase().includes("time")) {
      return (
        <Stack gap={0.5}>
          <TimeFieldInput
            onDataChange={async (id, value) => handleOnChange(id, key, value)}
            children={[]}
            name={key}
            required={false}
            type="date"
            label={key}
            isEditing
            id={v4()}
            value={value}
          />
        </Stack>
      )
    } else {
      return (
        <Stack gap={0.5}>
          <TextFieldInput
            onDataChange={async (id, value) => handleOnChange(id, key, value)}
            children={[]}
            name={key}
            required={false}
            type="text"
            label={key}
            isEditing
            id={v4()}
            value={value}
          />
        </Stack>
      )
    }
  }

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }))

  const renderLightBox = () =>
    displayProps && (
      <Lightbox
        carousel={{ finite: true }}
        index={activeIndex}
        open={openLightBox}
        plugins={[Zoom, Thumbnails, Captions]}
        close={() => setOpenLightBox(false)}
        slides={displayProps.properties.images.map((image: string) => {
          return {
            src: image,
            description: "",
          }
        })}
        // slides={displayProps.properties.images.map((image: IFormAttachment) => {
        //   return {
        //     src: image.url ? image.url : image.data ? `data:image/png;base64,${image.data}` : "",
        //     description: (
        //       <>
        //         <Box
        //           bgcolor={hexToRGB(theme.palette.grey[900], 0.5)}
        //           alignItems={"center"}
        //           width={"100%"}
        //           justifyContent={"space-between"}
        //           py={1}
        //           px={2}
        //           display={"flex"}
        //           flexDirection={"row"}
        //           position={"fixed"}
        //           top={0}
        //           left={0}>
        //           <Box flexDirection={"row"} display={"flex"}>
        //             {image.orientation && (
        //               <Box
        //                 style={{
        //                   transform: `rotate(${-(image.orientation + 45)}deg)`,
        //                   transition: "transform 0.5s linear", // Smooth transition for rotation
        //                 }}
        //                 sx={{ color: "white" }}>
        //                 <ImCompass fontSize="24" />
        //               </Box>
        //             )}
        //             <Typography variant="body1" color={"white"} ml={2}>
        //               {image.orientation ? image.orientation.toFixed(1) : "N/A"}°
        //             </Typography>
        //           </Box>
        //           <Typography variant="body1" color={"white"}>
        //             {image.longitude ? image.longitude.toFixed(4) + ", " : ""}{" "}
        //             {image.latitude ? image.latitude.toFixed(4) : ""}
        //           </Typography>
        //         </Box>
        //       </>
        //     ),
        //   }
        // })}
      />
    )

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
        {value === index && <Box>{children}</Box>}
      </div>
    )
  }

  if (displayProps && Object.keys(displayProps).length === 0)
    return <Stack gap={0.5}>The feature has no display attributes</Stack>

  const renderMetadataTable = () => (
    <Stack gap={0.5} overflow={"hidden"} maxHeight={250} borderRadius={2} border={`solid 0.5px gray`}>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {displayProps &&
              Object.entries(displayProps.properties.metadata)
                .filter(
                  ([key, value]) =>
                    (typeof value === "string" || typeof value === "number") && !key.toLowerCase().includes("photo"),
                )
                .map(([key, value], index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell>
                        <Stack>
                          <Typography fontSize={11} fontWeight={700}>
                            {key}
                          </Typography>
                        </Stack>
                      </StyledTableCell>
                      <StyledTableCell align="right">{value as string}</StyledTableCell>
                    </StyledTableRow>
                  )
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )

  const renderPointDataTable = () => <PointDataTable displayProps={displayProps} />

  const renderFormDataTable = () => (
    <Stack gap={0.5} overflow={"hidden"} maxHeight={250} borderRadius={2} border={`solid 0.5px gray`}>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {displayProps &&
              Object.entries(displayProps.properties.formData)
                .filter(
                  ([key, value]) =>
                    (typeof value === "string" || typeof value === "number") &&
                    key.toLowerCase().includes("photo") === false,
                )
                .map(([key, value], index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell>
                        <Stack>
                          <Typography fontSize={11} fontWeight={700}>
                            {key}
                          </Typography>
                        </Stack>
                      </StyledTableCell>
                      <StyledTableCell align="right">{value as string}</StyledTableCell>
                    </StyledTableRow>
                  )
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )

  const renderImages = () => (
    <Stack gap={0.5} overflow={"scroll"} maxHeight={250} borderRadius={2} border={`solid 0.5px gray`}>
      {displayProps &&
        displayProps.properties.images.map((value, index) => (
          <Box
            key={index}
            sx={{ aspectRatio: "auto", cursor: "pointer" }}
            width={"100%"}
            component={"div"}
            onClick={() => {
              setActiveIndex(index)
              setOpenLightBox(true)
            }}>
            <img height={"100%"} width={"100%"} src={value} alt={value} />
          </Box>
        ))}
    </Stack>
  )

  const renderEditingProperties = () => (
    <Stack>
      <SheetTopBar
        leading={
          <Typography color={"white"}>
            Edit Feature {displayProps && (displayProps.properties.metadata["Feature Name"] as string)}
          </Typography>
        }
        onClose={closeSidebar}
      />
      <Stack gap={2} padding={4}>
        {displayProps &&
          Object.entries(displayProps).map(([key, value], index) => (
            <Stack key={index} gap={0.5}>
              {renderPropertyInput(key, value)}
            </Stack>
          ))}
        {/* <Stack direction={"row"} gap={1}>
          <ConfirmationButton
            title="Are you sure?"
            message="This will delete the feature permanently."
            startIcon={<HiOutlineTrash />}
            size="small"
            color="error"
            disableElevation
            onConfirm={handleOnDelete}
            onCancel={closeSidebar}
            stylecolor="#f00">
            Delete
          </ConfirmationButton>
          <Button
            variant="contained"
            disableElevation
            sx={{
              textTransform: "capitalize",
              gap: 0.5,
              flexDirection: "row",
              alignItems: "center",
              paddingX: 2,
              borderRadius: 24,
              fontWeight: 500,
            }}
            onClick={handleOnSave}>
            <HiCheck />
            Save
          </Button>
        </Stack> */}
      </Stack>
    </Stack>
  )

  if (isEditing) {
    return renderEditingProperties()
  }

  return (
    <Stack gap={2} minHeight={300}>
      {renderTabs()}
      <CustomTabPanel index={0} value={value}>
        {renderMetadataTable()}
      </CustomTabPanel>

      <CustomTabPanel index={1} value={value}>
        {renderFormDataTable()}
      </CustomTabPanel>

      {displayProps && displayProps.properties.pointData && (
        <CustomTabPanel index={2} value={value}>
          {renderPointDataTable()}
        </CustomTabPanel>
      )}

      {displayProps && displayProps.properties.images && displayProps.properties.images.length > 0 && (
        <CustomTabPanel index={displayProps.properties.pointData ? 3 : 2} value={value}>
          {renderImages()}
          {renderLightBox()}
        </CustomTabPanel>
      )}
    </Stack>
  )
}

export default FeatureProperties
