import React, { useContext, useEffect, useRef } from "react"
import { Outlet } from "react-router"
import type { Theme } from "@mui/material"
import { Box, colors, Drawer, Stack, useMediaQuery } from "@mui/material"

import { design } from "../constants"
import { Sidebar, StatusBar, Topbar } from "../components"
import { useWindowSize } from "usehooks-ts"
import LayoutContext from "../contexts/layout.context"
import { Resizable } from "re-resizable"

function DashboardLayout() {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"))
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"))
  const { layoutState, closeSidebar, closeActionSheet, toggleNavigation, resizeSidebar } = useContext(LayoutContext)
  const { width, height } = useWindowSize()
  const [isDragging, setIsDragging] = React.useState(false)
  const [sidebarWidth, setSidebarWidth] = React.useState(design.rightDrawerWidth)

  // Add a ref to track initial render
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      console.log("DashboardLayout -> layoutState.navigationMode", layoutState.navigationMode)
      console.log("DashboardLayout -> isTablet", isTablet)
      if (isTablet && layoutState.navigationMode === "full") {
        toggleNavigation()
      }
      isInitialMount.current = false
    }
  }, [isTablet, toggleNavigation])

  // Handler for backdrop clicks
  const handleBackdropClick = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      closeSidebar()
    }
  }

  return (
    <Stack
      justifyContent={isDesktop ? "left" : "space-around"}
      flexDirection={isDesktop ? "row" : "column"}
      position={"relative"}>
      {isDesktop ? <Sidebar /> : <Topbar />}

      <Stack
        flex={1}
        marginLeft={
          isDesktop
            ? layoutState.navigationMode === "full"
              ? `${design.drawerWidth}px`
              : `${design.drawerMinimizedWidth}px`
            : 0
        }
        sx={{
          transitionProperty: "all",
          transitionDuration: "0.1s",
          transitionTimingFunction: "ease-in-out",
        }}
        position={"relative"}>
        <Stack
          sx={{
            transitionProperty: "all",
            transitionDuration: "0.1s",
            transitionTimingFunction: "ease-in-out",
          }}
          width={isDesktop && layoutState.sidebarOpen ? `calc(100% - ${sidebarWidth}px)` : "100%"}>
          <StatusBar mode={sidebarWidth > width / 2.5 ? "compact" : "regular"} />
          <Outlet />
        </Stack>

        {/* Added: Backdrop for desktop view */}
        {isDesktop && layoutState.sidebarOpen && (
          <Box
            onClick={handleBackdropClick}
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              bgcolor: "rgba(0, 0, 0, 0.2)",
              zIndex: 998,
            }}
          />
        )}

        <Drawer
          onClose={closeSidebar}
          hideBackdrop={isDesktop} // Only hide backdrop for desktop
          variant="persistent"
          elevation={0}
          PaperProps={{
            style: {
              zIndex: 999,
              background: "white",
              borderTopLeftRadius: layoutState.sidebarMode === "half" ? 10 : 0,
              borderTopRightRadius: layoutState.sidebarMode === "half" ? 10 : 0,
              boxShadow: layoutState.navigationMode === "minimized" ? "none" : "rgb(0 0 0 / 22%) 0px 0px 10px -3px",
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
          open={layoutState.sidebarOpen}
          anchor={isDesktop ? "right" : "bottom"}>
          <Box position={"relative"}>
            {layoutState.sidebarMode === "full" && (
              <Resizable
                enable={{
                  left: isDesktop,
                }}
                onResizeStart={() => setIsDragging(true)}
                onResize={(e) => {
                  const marginRight = width - (e as MouseEvent).x
                  resizeSidebar(marginRight)
                  setSidebarWidth(marginRight)
                }}
                onResizeStop={() => setIsDragging(false)}
                minHeight={height}
                handleComponent={{
                  left: isDesktop ? (
                    <Box
                      position={"absolute"}
                      top={"50%"}
                      zIndex={999}
                      sx={{ opacity: isDragging ? 0 : 1 }}
                      width={4}
                      height={56}
                      bgcolor={colors.grey[400]}
                      borderRadius={48}
                      left={10}
                    />
                  ) : undefined,
                }}
                handleStyles={{ left: { height, backgroundColor: isDragging ? colors.blue[700] : "transparent" } }}
                maxWidth={isDesktop ? width / 2 : "100%"}
                bounds={!isDesktop ? "window" : undefined}
                defaultSize={{ width: isDesktop ? design.rightDrawerWidth : undefined }}>
                <>{layoutState.sidebar}</>
              </Resizable>
            )}

            {layoutState.sidebarMode === "half" && (
              <Box height={!isDesktop ? height / 1.5 : 320} width={"100%"}>
                <>{layoutState.sidebar}</>
              </Box>
            )}
          </Box>
        </Drawer>
        <Drawer
          onClose={closeActionSheet}
          elevation={0}
          PaperProps={{
            style: {
              zIndex: 9999,
              background: "transparent",
              boxShadow: "none",
              width: isDesktop ? width / 3 : "100%",
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
          open={layoutState.sheetOpen}
          anchor={"bottom"}>
          <>
            <Box minHeight={height / 2} display={"flex"} alignItems={"flex-end"} width={"100%"}>
              <>{layoutState.actionSheet}</>
            </Box>
          </>
        </Drawer>
      </Stack>
    </Stack>
  )
}

export default DashboardLayout
